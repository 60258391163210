<!--
 * @Description: 登录 login
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-08 14:18:02
 * @LastEditors: houjinduo
-->
<template>
  <div class='body'>
    <van-row class="logo">
      <img src="@/assets/login/logo1.png"
           alt="">
    </van-row>
    <van-row class="content">
      <van-row class="content-text">
        <span>渝北停车智巡</span>
      </van-row>
      <van-form class="content-field"
                @submit="handleClickLogin">
        <van-field v-model="userCode"
                   :rules="[{ required: true}]"
                   placeholder="请输入账号">
          <template #left-icon>
            <div style="margin-right:12px">
              <van-image width="13.5"
                         height="19.5"
                         style="margin-top:3px"
                         :src="require('@/assets/login/shoujihao.png')" />
            </div>
          </template>
        </van-field>
        <van-field v-model="passWord"
                   type="password"
                   :rules="[{ required: true }]"
                   placeholder="请输入密码">
          <template #left-icon>
            <div style="margin-right:12px">
              <van-image width="14"
                         height="17.5"
                         style="margin-top:3px"
                         :src="require('@/assets/login/mima.png')" />
            </div>
          </template>
        </van-field>
        <van-field v-model="validateCode"
                   :rules="[{ required: true }]"
                   placeholder="请输入验证码">
          <template #left-icon>
            <div style="margin-right:12px">
              <van-image width="14px"
                         height="17.5px"
                         style="margin-top:16px"
                         :src="require('@/assets/login/yanzhengma.png')" />
            </div>
          </template>
          <template #right-icon>
            <div>
              <img width="100px"
                   height="40px"
                   ref="vcImg"
                   :src="verifyCode"
                   @click="getVerifyCode()" />
            </div>
          </template>
        </van-field>
        <van-button native-type="submit"
                    class="loginButton">登 录</van-button>
      </van-form>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import md5 from 'js-md5'
export default {
  // import引入的组件需要注入到对象中才能使用
  data () {
    // 这里存放数据
    return {
      // 账号
      userCode: '',
      // 密码
      passWord: '',
      // 验证码
      validateCode: '',
      // 接口获取到的验证码
      verifyCode: '',
      uuid: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // 登录过的用户直接跳转首页
    if (localStorage.getItem('userToken') !== null) {
      this.$router.replace({ path: '/homePage' })
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getVerifyCode()
    if (localStorage.getItem('userCode')) {
      this.userCode = localStorage.getItem('userCode')
    }
    if (localStorage.getItem('passWord')) {
      this.passWord = localStorage.getItem('passWord')
    }
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 点击刷新验证码
    getVerifyCode () {
      this.$login.captchaImage().then(res => {
        this.verifyCode = 'data:image/png;base64,' + res.image
        this.uuid = res.uuid
      })
      // this.$refs.vcImg.src = '/cqparkApi/login/LoginController/loginValidateCode?' + Math.random()
    },
    // 登录按钮
    handleClickLogin () {
      const info = {
        userCode: this.userCode,
        passWord: md5(this.passWord),
        captchaCode: this.validateCode,
        uuid: this.uuid
      }
      this.$login.loginIn(info).then(res => {
        localStorage.setItem('userToken', res.resultEntity.userToken)
        localStorage.setItem('userCode', res.resultEntity.userCode)
        localStorage.setItem('userName', res.resultEntity.userName)
        this.$toast('登录成功')
        this.$router.replace({ path: '/homePage' })
      }).catch(() => {
        this.$toast('登录失败')
      })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url('../../assets/login/backGround.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .logo {
    left: 33%;
    position: absolute;
    z-index: 999;
    top: 50px;
  }
  .content {
    width: 93.6%;
    margin-left: 3.2%;
    margin-top: 110px;
    background: #fff;
    -webkit-box-shadow: #999 0px 0px 10px;
    -moz-box-shadow: #999 0px 0px 10px;
    box-shadow: 0px 0px 65px 0px rgba(96, 96, 96, 0.2);
    border-radius: 5px;
    .content-text {
      font-size: 28px;
      color: #333333;
      text-align: center;
      padding-top: 80px;
      font-weight: bold;
    }
    .content-field {
      margin-top: 40px;
    }
    .loginButton {
      width: 93.6%;
      margin-left: 3.2%;
      margin-top: 73px;
      margin-bottom: 50px;
      background: #19a9fc;
      color: white;
      font-size: 17px;
      border-radius: 6px;
    }
  }
}
</style>
